import { defineComponent as _defineComponent } from 'vue'
import TheStep from "@/components/TheStep/TheStep.vue";
import TenantDetailForm from "@ems/containers/Tenant/TheForm/TenantDetail.Form.vue";
import StatusCard from "@/components/Common/StatusCard.vue";
import RemarkCard from "@/components/Common/RemarkCard.vue";
import TheClock from "@/components/TheClock.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import { ITenantRegistration } from "@/models/Tenant";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import TenantRegistrationForm from "@ems/containers/Tenant/TheForm/TenantRegistration.Form.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { useRouter } from "vue-router";
import CompanyInformationApproval from "@/components/Common/FormHelpper/CompanyInformationApproval.vue";
import RequiredDocuments from "@/components/Common/FileHelper/RequiredDocuments.vue";

import { onMounted, ref } from "vue";
import {
  ACCOUNT_REGISTRATION_PROGRESS,
  accountRegistrationProgress,
} from "@ems/constants/steps";
import {
  getTenantId,
  setTenantIsActive,
  setTenantStatus,
} from "@/utils/storage";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantWaitingApproval',
  setup(__props, { expose: __expose }) {
  __expose();

const currentStep = ref<ACCOUNT_REGISTRATION_PROGRESS>(
  ACCOUNT_REGISTRATION_PROGRESS.STATUS
);

const router = useRouter();
const tenantData = ref<ITenantRegistration>();
const formStatus = ref(STATUS_TENANT.Pending);

const fetchTenant = async (tenantId: string) => {
  await TenantUserModule.getTenantAccountRegistrationAction(tenantId ?? "");
  const dataRegistration = TenantUserModule.dataTenantRegistrationGetter;
  if (dataRegistration) {
    tenantData.value = {
      ...dataRegistration,
    } as ITenantRegistration;
    formStatus.value = tenantData.value.Status!;
  }
};

onMounted(async () => {
  await fetchTenant(getTenantId()!);

  if (tenantData.value?.Status === STATUS_TENANT.Approved) {
    setTenantStatus(STATUS_TENANT.Approved);
    setTenantIsActive(true);
    return router.replace("/");
  }
});

const __returned__ = { currentStep, router, tenantData, formStatus, fetchTenant, TheStep, TenantDetailForm, StatusCard, RemarkCard, TheClock, TheFooter, TheHeader, get ITenantRegistration() { return ITenantRegistration }, get STATUS_TENANT() { return STATUS_TENANT }, TenantRegistrationForm, BaseSpinner, get TenantUserModule() { return TenantUserModule }, get useRouter() { return useRouter }, CompanyInformationApproval, RequiredDocuments, onMounted, ref, get ACCOUNT_REGISTRATION_PROGRESS() { return ACCOUNT_REGISTRATION_PROGRESS }, get accountRegistrationProgress() { return accountRegistrationProgress }, get getTenantId() { return getTenantId }, get setTenantIsActive() { return setTenantIsActive }, get setTenantStatus() { return setTenantStatus } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})