import { defineComponent as _defineComponent } from 'vue'
import { ITenantRegistration } from "@/models/Tenant";
import { PropType, ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyInformationApproval',
  props: {
  initialValues: {
    type: Object as PropType<ITenantRegistration>,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const formValues = ref({ ...props.initialValues });

watch(
  () => props.initialValues,
  (newValues) => {
    formValues.value = { ...newValues };
  },
  { deep: true, immediate: true }
);

const __returned__ = { props, formValues, get ITenantRegistration() { return ITenantRegistration }, PropType, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})