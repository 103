import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white shadow-lg rounded-lg px-5 py-5 text-neutral-dark w-full" }
const _hoisted_2 = { class: "flex space-x-small" }
const _hoisted_3 = { class: "flex space-x-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "font-bold text-lg pb-1" }, "Company Information", -1)),
    _createVNode(_component_el_form, {
      model: $setup.formValues,
      "label-position": "top",
      "label-width": "150px",
      class: "custom-form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form_item, {
            class: _normalizeClass([$setup.formValues.Province ? 'half-width' : 'full-width', "label-custom"]),
            label: "Country"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.formValues.Country,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formValues.Country) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["class"]),
          ($setup.formValues.Province)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                class: "half-width label-custom",
                label: "Province"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.formValues.Province,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formValues.Province) = $event)),
                    autocomplete: "off",
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_el_form_item, {
          label: "Company Registration Number",
          class: "label-custom"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.formValues.CompanyRegistrationNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formValues.CompanyRegistrationNumber) = $event)),
              autocomplete: "off",
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Company Name",
          class: "label-custom"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.formValues.CompanyName,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formValues.CompanyName) = $event)),
              autocomplete: "off",
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Address",
          class: "label-custom"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.formValues.Address,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formValues.Address) = $event)),
              autocomplete: "off",
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form_item, {
            class: "width-one-third label-custom",
            label: "Phone Code"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.formValues.PhoneCode,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formValues.PhoneCode) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            class: "width-two-third label-custom",
            label: "Contact Number"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.formValues.ContactNumber,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formValues.ContactNumber) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}