import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-neutral-light flex flex-col" }
const _hoisted_2 = { class: "relative flex-1" }
const _hoisted_3 = {
  class: "absolute -top-0 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_4 = { class: "flex w-full items-center justify-center my-8" }
const _hoisted_5 = { class: "flex flex-col lg:flex-row gap-2 pb-10 max-w-screen-xl min-h-screen w-full mx-auto" }
const _hoisted_6 = { class: "flex flex-col lg:w-2/3 gap-2" }
const _hoisted_7 = { class: "flex flex-col lg:w-1/3 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TheHeader"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["TheClock"])
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["TheStep"], {
        currentStep: $setup.currentStep,
        steps: $setup.accountRegistrationProgress
      }, null, 8, ["currentStep", "steps"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["CompanyInformationApproval"], { "initial-values": $setup.tenantData }, null, 8, ["initial-values"]),
        _createVNode($setup["RequiredDocuments"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode($setup["StatusCard"], { status: $setup.formStatus }, null, 8, ["status"]),
        _createVNode($setup["RemarkCard"], {
          remark: $setup.tenantData?.RemarkContent,
          status: $setup.formStatus,
          "processed-date": $setup.tenantData?.ProcessedDate
        }, null, 8, ["remark", "status", "processed-date"])
      ])
    ]),
    _createVNode($setup["TheFooter"], { class: "page-footer" })
  ]))
}